import React from 'react';
// import { AccountSettings } from '../components'; // Adjust the path if necessary
import { EditProfile } from '../components';

const Settings = () => {
  return (
    <div className='settings-page'>
      {/* <AccountSettings /> */}
      <EditProfile />
    </div>
  );
}

export default Settings;
