import ProposalForm from '../components/proposalForm';

const Create = () => {

  return (
    <div>
        <ProposalForm />
    </div>
  );
};

export default Create;